import React from 'react'
import Seo from '../components/Seo'
import '../styles/global.css'

// markup
const IndexPage = () => {
  return (
    <>
      <Seo />
      <div className="m-6">
        <div className="mx-auto max-w-3xl leading-snug text-gray-900">
          <div className="text-center">Coming Soon</div>
        </div>
      </div>
    </>
  )
}

export default IndexPage
